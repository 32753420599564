import brand_1 from '../../../assets/Logs/screens.png'
import brand_2 from '../../../assets/Logs/javascript.png'
import brand_3 from '../../../assets/Logs/react.png'
import brand_4 from '../../../assets/Logs/nodeJs.png'
import brand_5 from '../../../assets/Logs/html5.png'
import brand_6 from '../../../assets/Logs/css3.png'
import brand_7 from '../../../assets/Logs/tailwindcss.png'
import brand_8 from '../../../assets/Logs/aws.png'
import brand_9 from '../../../assets/Logs/graphQl.png'
import brand_10 from '../../../assets/Logs/pgAdmin.png'
import brand_11 from '../../../assets/Logs/sequelize-logo.png'
import brand_12 from '../../../assets/Logs/Visual_Studio_Code.png'

//import brand_13 from '../../../assets/Logs/Wordpress.png'
//import brand_14 from '../../../assets/Logs/Elementor-Logo.png'
//import brand_15 from '../../../assets/Logs/divi log.png'
//import brand_16 from '../../../assets/Logs/octopus.png'

//import brand_17 from '../../../assets/Logs/corel-draw.png'
//import brand_18 from '../../../assets/Logs/ilustrator.png'
//import brand_19 from '../../../assets/Logs/photoshop.png'
//import brand_20 from '../../../assets/Logs/gifAnimator.png'
//import brand_21 from '../../../assets/Logs/videoEditor.png'
//import brand_22 from '../../../assets/Logs/copywriter.png'
//import brand_23 from '../../../assets/Logs/facebookads.png'
//import brand_24 from '../../../assets/Logs/googleAds.png'
//import brand_25 from '../../../assets/Logs/instagram-reel.png'
//import brand_26 from '../../../assets/Logs/mercadolibre.png'
//import brand_27 from '../../../assets/Logs/tienda nube.png'
//import brand_28 from '../../../assets/Logs/digitalMarketing.png'

export const LogoSliderData = [
    {
        img: brand_1,
        key: "brand_1",
        alt: "Responsive design"
    },
    {
        img: brand_2,
        key: "brand_2",
        alt: "JavaScript"
    },
    {
        img: brand_3,
        key: "brand_3",
        alt: "React JS"
    },
    {
        img: brand_4,
        key: "brand_4",
        alt: "Node JS"
    },
    {
        img: brand_5,
        key: "brand_5",
        alt: "HTML"
    },
    {
        img: brand_6,
        key: "brand_6",
        alt: "CSS"

    },
    {
        img: brand_7,
        key: "brand_7",
        alt: "Tailwind CSS"
    },
    {
        img: brand_8,
        key: "brand_8",
        alt: "Amazon Web Services"
    },
    {
        img: brand_9,
        key: "brand_9",
        alt: "Graph QL"
    },
    {
        img: brand_10,
        key: "brand_10",
        alt: "Pg Admin"
    },
    {
        img: brand_11,
        key: "brand_11",
        alt: "Sequelize"
    },
    {
        img: brand_12,
        key: "brand_12",
        alt: "Visual Studio Code"
    },
    {/* 
        {
            img: brand_13,
            key: "brand_13",
        },
        {
            img: brand_14,
            key: "brand_14",
        },
        {
            img: brand_15,
            key: "brand_15",
        },
        {
            img: brand_16,
            key: "brand_16",
        },
        {
            img: brand_17,
            key: "brand_17",
        },
        {
            img: brand_18,
            key: "brand_18",
        },
        {
            img: brand_19,
            key: "brand_19",
        },
        {
            img: brand_20,
            key: "brand_20",
        },
        {
            img: brand_21,
            key: "brand_21",
        },
        {
            img: brand_22,
            key: "brand_22",
        },
        {
            img: brand_23,
            key: "brand_23",
        },
        {
            img: brand_24,
            key: "brand_24",
        },
        {
            img: brand_25,
            key: "brand_25",
        },
        {
            img: brand_26,
            key: "brand_26",
        },
        {
            img: brand_27,
            key: "brand_27",
        },
        {
            img: brand_28,
            key: "brand_28",
        },
        */}
]